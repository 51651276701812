body,
html {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  color: #ffffff;
  height: 100vh;
  background-attachment: fixed;
  /* background-image: url('background.webp'); */
  /* background-size: fill; */
  /* background-position: center; */
  /* animation: animateBg 10s linear infinite; */
  /* background-image: linear-gradient(
    0deg,
    #cf5c5c,
    #c19b4a,
    #def2a0,
    #c6ee4a,
    #42eca6,
    #64b3d9,
    #208ea2,
    #498ada,
    #5b73df,
    #897ed3,
    #cf5c5c,
    #c19b4a
  ); */
  --x: 50%;
  --y: 50%;
  background-image: #000000;
  background-image: radial-gradient(
      at 52% 10%,
      hsla(73, 82%, 65%, 1) 0,
      transparent 42%
    ),
    radial-gradient(at 12% 26%, hsla(144, 82%, 65%, 1) 0, transparent 45%),
    radial-gradient(at 33% 66%, hsla(218, 91%, 71%, 1) 0, transparent 31%),
    radial-gradient(at 87% 12%, hsla(144, 82%, 65%, 1) 0, transparent 58%),
    radial-gradient(at 6% 14%, hsla(139, 82%, 65%, 1) 0, transparent 48%),
    radial-gradient(at 100% 100%, hsla(146, 67%, 59%, 1) 0, transparent 18%),
    radial-gradient(at 2% 48%, hsla(142, 82%, 65%, 1) 0, transparent 16%),
    radial-gradient(at 35% 10%, hsla(149, 80%, 81%, 1) 0, transparent 25%),
    radial-gradient(at 79% 70%, hsla(217, 100%, 50%, 1) 0, transparent 100%),
    radial-gradient(at 13% 75%, hsla(217, 73%, 57%, 1) 0, transparent 100%);
  /* background-size: 100% 150%; */
  overflow: overlay;
}

/* .css-selector {
  "background-image", "linear-gradient("+ i +"deg,rgba(204,65,37,0.5), rgba(147,196,25,0.5)), linear-gradient(0deg, blue, rgba(194,123,160,0.5))"
} */
@keyframes animateBg {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
