/* Customize your main colors in :root variables */
:root {
  --main-background-color: #ffffff70;
  --card-background-color: #e8b028;
  --card-background-lighter-color: #ffc12b;
  --main-text-color: #ffffff70;
  --title-text-color: #00000070;
}
/* width */
::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: #00000000;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 10px;
  margin: 1px;
  box-shadow: inset 0 0 10px 10px #ffffff70;
  border: solid 3px transparent;
}

::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 10px 10px #ffffffb0;
}
body {
  background-color: var(--main-background-color);
  font-family: "Manrope", sans-serif;
  font-size: 1.2em;
}

h2,
p {
  color: var(--main-text-color);
}
